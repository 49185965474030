import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';
import {
  setUser,
  login,
  signUp,
  forgetPassword,
  setAuth,
  clearAuth,
  getUser,
  updateUser,
  addPayment,
  checkReferral,
  getTransactionPaymentLink,
  getTransactions,
  getMoreTransactions,
  deletePayment,
  terminateUser,
  schufaCheck,
  sendVerificationCode,
  joinCircle,
  removeCircle,
  checkCircle,
  uploadDocument,
  getPaymentPublicKey,
  submitIdentityShopper,
  submitChallenge,
  isPaymentVerified,
  setRecoverPaymentAuth,
  getPaymentData,
  setActivePaymentData,
  getPaymentMethods,
  addPaymentDetails,
} from '../redux/store/userData/user';
import { PaymentRequestData } from '../@types';
import {
  deletePhone,
  getGDPR,
  reSendVerificationEmail,
  verifyEmail,
} from '../redux/store/userData/user/actions';
import {
  setIsVerifyingGDPREmail,
  setIsEditingPhone,
} from '../redux/store/ui/firstSteps';

export type Props = {
  email: string;
  user: any | null | undefined;
  basicAuth: string | null | undefined;
  paymentPublicKey: string | null | undefined;
  recoverPaymentAuth: boolean;
  checkoutPaymentMethods: any;
  transactions: any;
  setUser: typeof setUser;
  login: typeof login;
  signUp: typeof signUp;
  forgetPassword: typeof forgetPassword;
  setAuth: typeof setAuth;
  clearAuth: typeof clearAuth;
  getUser: typeof getUser;
  updateUser: typeof updateUser;
  terminateUser: typeof terminateUser;
  addPayment: typeof addPayment;
  deletePayment: typeof deletePayment;
  checkReferral: typeof checkReferral;
  getTransactionPaymentLink: typeof getTransactionPaymentLink;
  getTransactions: typeof getTransactions;
  getMoreTransactions: typeof getMoreTransactions;
  schufaCheck: typeof schufaCheck;
  sendVerificationCode: typeof sendVerificationCode;
  joinCircle: typeof joinCircle;
  removeCircle: typeof removeCircle;
  checkCircle: typeof checkCircle;
  uploadDocument: typeof uploadDocument;
  getPaymentPublicKey: typeof getPaymentPublicKey;
  submitIdentityShopper: typeof submitIdentityShopper;
  submitChallenge: typeof submitChallenge;
  isPaymentVerified: typeof isPaymentVerified;
  setRecoverPaymentAuth: typeof setRecoverPaymentAuth;
  getPaymentData: typeof getPaymentData;
  activePaymentData: PaymentRequestData | null | undefined;
  setActivePaymentData: typeof setActivePaymentData;
  getPaymentMethods: typeof getPaymentMethods;
  addPaymentDetails: typeof addPaymentDetails;
  isVerifyingGDPREmail: boolean;
  isEditingPhone: boolean;
  reSendVerificationEmail: typeof reSendVerificationEmail;
  getGDPR: typeof getGDPR;
  verifyEmail: typeof verifyEmail;
  setIsVerifyingGDPREmail: typeof setIsVerifyingGDPREmail;
  setIsEditingPhone: typeof setIsEditingPhone;
  deletePhone: typeof deletePhone;
};

const withUser: any = compose(
  connect(
    ({
      userData: {
        user: {
          email,
          user,
          basicAuth,
          transactions,
          paymentPublicKey,
          recoverPaymentAuth,
          signUpCircle,
          activePaymentData,
          checkoutPaymentMethods,
        },
      },
      ui: {
        firstSteps: { isVerifyingGDPREmail, isEditingPhone },
      },
    }: State) => ({
      email,
      user,
      basicAuth,
      transactions,
      paymentPublicKey,
      recoverPaymentAuth,
      signUpCircle,
      activePaymentData,
      checkoutPaymentMethods,
      isVerifyingGDPREmail,
      isEditingPhone,
    }),
    {
      setUser,
      login,
      signUp,
      forgetPassword,
      setAuth,
      clearAuth,
      getUser,
      updateUser,
      terminateUser,
      addPayment,
      deletePayment,
      checkReferral,
      getTransactionPaymentLink,
      getTransactions,
      getMoreTransactions,
      schufaCheck,
      sendVerificationCode,
      joinCircle,
      removeCircle,
      checkCircle,
      uploadDocument,
      getPaymentPublicKey,
      submitIdentityShopper,
      submitChallenge,
      isPaymentVerified,
      setRecoverPaymentAuth,
      setActivePaymentData,
      getPaymentData,
      getPaymentMethods,
      addPaymentDetails,
      reSendVerificationEmail,
      getGDPR,
      verifyEmail,
      setIsVerifyingGDPREmail,
      setIsEditingPhone,
      deletePhone,
    }
  )
);
export default withUser;
