import { isAndroid } from '../helpers/platformHelpers';

export const UIManager = {
  nativeManager: null as any,

  showAlert(
    title: string | null | undefined,
    message: string | null | undefined,
    yesText: string,
    noText: string,
    yesAction: () => void,
    noAction: () => void = () => {}
  ) {
    if (isAndroid()) {
      this.nativeManager?.showAlert(
        title,
        message,
        yesText,
        noText,
        yesAction,
        noAction
      );
    }
  },

  showListAlert(
    title: string | null | undefined,
    items: Array<string>,
    onItemClicked: (arg0: Record<string, any>) => void
  ) {
    if (isAndroid()) {
      this.nativeManager?.showListAlert(title, items, onItemClicked);
    }
  },
};
